export const commercialOfferList = (apiUrl, user) => {
    let oldSession = []
    const packagesContainer = document.getElementById('packages');
    const selectedPackages = new Set();
    let auth = user.role.find((e) => e.limit !== null && e.limit === 'limited')

    let listTab = [{serviceName: 'custom', service_id: 21, priceDetail: '€', price_per_package: 0, serviceDetail: '', optionnal: true, exclude_count: false}]

  

    const partial = (service) => {
        return `
        <div class="item el_1">
        Min&nbsp;&nbsp;<input type="text" disabled class="service-price" id="min-price-${service.service_id}" value="${(service.minimum_price !== null && auth !== undefined) ? service.minimum_price : service.price_per_package}" size="4" step="1">
        </div>`
    }

    const renderServices = (services, language) => {
        services.sort((a, b) => a.service_id - b.service_id); // Sort services by ID
        const servicesHtml = services.map((service) => {
            let serviceName, priceDetail, serviceDetail;
   
            if (language === 'fr') {
                serviceName = service.service_name_fr;
                priceDetail = service.service_price_details_fr;
                serviceDetail = service.service_details_fr;
            } else {
                serviceName = service.service_name_en;
                priceDetail = service.service_price_details_en;
                serviceDetail = service.service_details_en;
            }
         
            return `
            <div class="service">           
                <div class="line">
                <div class="item el_0">
                    <input type="checkbox" name="${serviceName}" class="service-checkbox" id="service-${service.service_id}" value="${service.service_id}" data-service-detail="${serviceDetail}" serviceType=${service.optionnal ? 'Optional' : 'Basic'} exclude="${service.exclude_count}" priceDetail="${priceDetail}">
                    <div class="service-name">${serviceName}</div>
                    <span class="option"> ${service.optionnal ? '&nbsp;(Optional)' : ''}</span>
                </div>
                ${auth === undefined ? '' : partial(service)}
               
                <div class="item el_2">
                    <input type="text" class="service-price" id="price-${service.service_id}" value="${service.price_per_package}" size="4" step="1">${priceDetail}
                </div>
                <div class="item el_3">
                    <select id="displayType" name="displayType" class="display-type">
                        <option value="basic">Basic</option>
                        <option value="complementary">Complementaire</option>
                        <option value="optional">Optionel</option>
                    </select>
                </div> 
                </div>       
            </div>`;
        }).join('');
        return servicesHtml;
    }



    const renderServicesCustom = (services, language) => {
        
        const servicesHtml = listTab.map((e, index) => {
            return `
            <button id="commercialBtnPlus">Add item</button>
            <div class="service service_21"> 
       
                <div class="line service">
                    <div class="item el_0">
                        <input type="checkbox" name="${e.serviceName}" class="service-checkbox" id="service-${e.service_id}" value="${e.service_id}" data-service-detail="${e.serviceDetail}" serviceType=${e.optionnal ? 'Optional' : 'Basic'} exclude="${e.exclude_count}" priceDetail="${e.priceDetail}">
                        <input type="text" class="service-price service-name" id="services-${e.service_id}"  placeholder="type..." size="15" step="1">
                        <span class="option"> ${e.optionnal ?? '&nbsp;(Optional)'}</span>
                    </div>  
                    <div class="item el_2">
                        <input type="text" class="service-price" id="price-${e.service_id}" value="" size="4" step="1" placeholder="0">${e.priceDetail}
                    </div>
                <div class="item el_3">

                    <select id="displayType" name="displayType" class="display-type">
                        <option value="basic">Basic</option>
                        <option value="complementary">Complementaire</option>
                        <option value="optional">Optionel</option>
                    </select>
                </div>
                </div> 
            </div>`
        }).join('');

        return servicesHtml
    }

    const renderCustomPackage = () => {
        packagesContainer.innerHTML = (
            `<div class="package pack_${1}">
                <div class="wrap">
                    <div class="line">
                        <div class="items">
                            <input type="checkbox" class="package-checkbox" id="package-${21}" value="${21}" name="custom">
                            <span class="localisation">fr</span>
                        </div>
                    </div>
                    <div class="services-for-package" id="services-for-21"></div>
                </div>
            </hr>
            </div>
    `).join('');
    }

    const renderPackages = (packages) => {
        const sortedPackages = packages.sort((a, b) => a.package_id - b.package_id); // Sorting packages by ID
        packagesContainer.innerHTML = sortedPackages.map((pkg, index) => (
        `<div class="package pack_${index}">
            <div class="wrap">
                <div class="line">
                    <div class="items">
                        <input type="checkbox" class="package-checkbox" id="package-${pkg.package_id}" value="${pkg.package_id}" name="${pkg.package_name}">
                        ${pkg.package_name}
                        <span class="localisation">${pkg.localisation}</span>
                    </div>
                </div>
                <div class="services-for-package" id="services-for-${pkg.package_id}"></div>
            </div>
        </hr>
        </div>
        `)).join('');

        packages.forEach(pkg => {
            const packageCheckbox = document.getElementById(`package-${pkg.package_id}`);
            packageCheckbox.addEventListener('change', () => {
                const servicesForPackageContainer = document.getElementById(`services-for-${pkg.package_id}`);
                const selectedLanguage = document.querySelector('input[name="language"]:checked').value;
                const isChecked = packageCheckbox.checked;

                if(isChecked){           
                    selectedPackages.add(pkg.package_id);
                    Promise.all([
                        fetch(`${apiUrl}/api/v1/internalTools/commercial/services/basic?package_id=${pkg.package_id}&language=${selectedLanguage}`).then(response => response.json()),
                        fetch(`${apiUrl}/api/v1/internalTools/commercial/services/optional?package_id=${pkg.package_id}&language=${selectedLanguage}`).then(response => response.json())
                    ])
                    .then((values) => { 
                        const result = [...values[0], ...values[1]] 
            
                        const servicesHtml = 
                        (result && result.length>0) ? 
                        renderServices(result, selectedLanguage) : 
                        renderServicesCustom([{}], selectedLanguage); // Basic service
                
                        servicesForPackageContainer.innerHTML = servicesHtml;

                    if(pkg.package_id>=packages.length){

                

                        // get default add button
                        let button = document.querySelector('#commercialBtnPlus')
                        let count = 1
    
                        button.addEventListener('click', (event) => {
                            event.preventDefault()
                            let list = document.querySelector('.service_21')
                            let item = document.createElement('div')
                            item.classList.add('line');
                            item.classList.add('service');
                    
                            let c = count+1
                    
                            item.innerHTML = `        
                      
                                    <div class="item el_0">
                                        <input type="checkbox" name="custom_${21+c}" class="service-checkbox" id="service-${21+c}" value="${21+c}" data-service-detail="" serviceType='Basic' exclude="" priceDetail="€">
                                        <input type="text" class="service-price service-name" id="services-${21+c}" placeholder="type..." value="" size="10" step="1">
                                    </div>  
                                    <div class="item el_2">
                                        <input type="text" name="service_price_${21+c}" class="service-price" id="price-${21+c}" placeholder="0" size="4" step="2">€
                                    </div>
                                    <div class="item el_3">
                                  
                                        <select id="displayType" name="displayType" class="display-type" step="3">
                                            <option value="basic">Basic</option>
                                            <option value="complementary">Complementaire</option>
                                            <option value="optional">Optionel</option>
                                        </select>
                                    </div> 
                                    <div class="item el_4">
                                        <button id="btns_del_${21+c}" class="commercialBtnDelete">delete</button>
                                    </div>
                            
                            `;
                           
                            list.appendChild(item);

                            let delButtom = document.querySelector(`#btns_del_${21+c}`)
                    
                            delButtom.addEventListener('click', (event) => {
                                event.preventDefault()
                                let container = delButtom.parentElement
                                let prevParent = container.parentElement
                                prevParent.remove()
                            })
                   
                        })
                    }
                    }).catch((error) => {
                        console.log(error)
                        console.error(error);
                        servicesForPackageContainer.innerHTML += 'Error fetching optional services from the server.';
                    })
                } else {
                    selectedPackages.delete(pkg.package_id);
                    servicesForPackageContainer.innerHTML = ''; // Clear services
                }
            });
        });
    }

    const languageRadios = document.querySelectorAll('input[name="language"]');
    languageRadios.forEach(radio => {
        radio.addEventListener('change', () => {
            const selectedLanguage = radio.value;
            refreshData(selectedLanguage);
        });
    });

    const refreshData = (language) => {
        fetch(`${apiUrl}/api/v1/internalTools/commercial/packages`)
            .then(response => response.json())
            .then(data => {

                data.length>0 ? renderPackages(data) : renderCustomPackage(data);

                selectedPackages.forEach(packageId => {

                Promise.all([
                    fetch(`${apiUrl}/api/v1/internalTools/commercial/services/basic?package_id=${packageId}&language=${language}`).then(response => response.json()),
                    fetch(`${apiUrl}/api/v1/internalTools/commercial/services/optional?package_id=${packageId}&language=${language}`).then(response => response.json())
                ])
                .then((values) => {
                    const result = [...values[0], ...values[1]]
          
                    const servicesHtml = 
                    result.length>0 ? 
                    renderServices(result, language) :
                    renderServicesCustom([{}], language); // Basic services

                    const container = document.getElementById(`services-for-${packageId}`);
                    container.innerHTML = servicesHtml;
                                
                }).catch((error) => {
                    console.error(error);
                    const container = document.getElementById(`services-for-${packageId}`);
                    container.innerHTML = 'Error fetching basic services from the server.';
                })
            });
        })
        .catch(error => {
            console.error(error);
            packagesContainer.innerHTML = 'Error fetching packages from the server.';
        });
    }

    refreshData('en'); // Initial data loading


    document.getElementById('form').addEventListener('submit', async (e) => {
        e.preventDefault();

        let loadWrapper = document.getElementById("download")
        let breakProcess = ''

        let selectedPackages = [];
        let memo = [];
        const selectedLanguage = document.querySelector('input[name="language"]:checked').value;
        let totalPacks = document.querySelectorAll('.package-checkbox:checked').length;
        let pageCount = totalPacks+=2
   
        document.querySelectorAll('.package-checkbox').forEach(checkbox => {
            if (checkbox.checked) {
                const packageId = checkbox.value;
                const packageName = checkbox.name;
                const services = [];
                const price = [];
                const clientDetail = [];

                const expirationDate = document.getElementById("expirationDate").value;
                const companyName = document.getElementById("CompanyName").value;
                const companyAdresse = document.getElementById("CompanyAdresse").value;
                const companyId = document.getElementById("CompanyId").value;
                const companyVAT = document.getElementById("CompanyVAT").value;
                const contactFirstname = document.getElementById("contactFirstname").value;
                const contactLastname = document.getElementById("contactLastname").value;
                const contactEmail = document.getElementById("contactEmail").value;
                const goodDetails = document.getElementById("goodDetails").value;

                let basicServiceSum = 0;
                let optionalServiceSum = 0;
                let complementaryServiceSum = 0;

                let haveComplementary = false;
                let haveOptional = false;
                let totalService = 0;

                let serviceChecBoxes = document.querySelectorAll(`#services-for-${packageId} .service-checkbox`)
   
                serviceChecBoxes.forEach((serviceCheckbox, i) => {                   
                    if(serviceChecBoxes[i].checked){
                                                
                        const serviceId = serviceChecBoxes[i].value;
                                
                        const minPriceValue = document.querySelector(`#services-for-${packageId} #min-price-${serviceId}`);
                        const servicePriceInput = document.querySelector(`#services-for-${packageId} #price-${serviceId}`);
                        const servicePrice = servicePriceInput && servicePriceInput.value;
                        if(minPriceValue){
                            if(Number(minPriceValue.value) > Number(servicePrice)){
                                memo.push(minPriceValue.value)                        
                                breakProcess = `Price cannot be inferior to minimum price`
                                loadWrapper.innerHTML = `Final price cannot be inferior to ${memo[0]} €`              
                            }
                        }
                        //let theId = `services-${serviceId}`

                       const serviceName = packageName !== 'CUSTOM' ?
                       serviceCheckbox.getAttribute('name') : 
                       document.querySelector(`#services-${serviceId}`).value;
                       
                        const displayTypeSelect = serviceCheckbox.closest('.service').querySelector('select[name="displayType"]');
                        const selectedDisplayType = displayTypeSelect.value;

                           
                            const servicePriceDetails = serviceCheckbox.getAttribute('priceDetail');
                            const excludeCount = serviceCheckbox.getAttribute('exclude');
                            const serviceDetail = serviceCheckbox.getAttribute('data-service-detail');
               
                              
                            let isBasic = false;
                            let isComplementary = false;
                            let isOptional = false;

                            // Update flags based on the selected displayType
                            if(selectedDisplayType === 'basic'){
                                isBasic = true;
                                if(excludeCount ==='false'){
                                    if(!isNaN(servicePrice)){
                                        basicServiceSum += parseFloat(servicePrice);
                                    }
                                
                                }
                            }else if(selectedDisplayType === 'complementary'){
                                    isComplementary = true;
                                    haveComplementary = true;
                                if(excludeCount ==='false'){
                                    if(!isNaN(servicePrice)){
                                        complementaryServiceSum += parseFloat(servicePrice);
                                    }
                                }
                            }else if(selectedDisplayType === 'optional'){
                                    isOptional = true;
                                    haveOptional=true;
                                if(excludeCount ==='false'){
                                    if(!isNaN(servicePrice)){
                                        optionalServiceSum += parseFloat(servicePrice);
                                    }
                                }
                            }

                               
                            // Push the flags into the services array
                            services.push({
                                service_name: serviceName,
                                price_per_package: servicePrice,
                                service_price_details: servicePriceDetails,
                                service_detail: serviceDetail,
                                isBasic: isBasic,
                                isComplementary: isComplementary,
                                isOptional: isOptional
                            });
                            
                        totalService = basicServiceSum + complementaryServiceSum  
                    }
                });

                let isEnglish = true;
                if (selectedLanguage === 'en'){
                    isEnglish = true
                }else{
                    isEnglish = false
                }
                
                price.push({
                    basicServiceSum: basicServiceSum,
                    complementaryServiceSum: complementaryServiceSum,
                    optionalServiceSum: optionalServiceSum,
                    total: totalService,
                })

                clientDetail.push({    
                    expirationDate : expirationDate,
                    companyName: companyName,
                    companyAdresse :companyAdresse,
                    companyId :companyId,
                    companyVAT :companyVAT,
                    contactFirstname : contactFirstname,
                    contactLastname : contactLastname,
                    contactEmail :contactEmail,
                    goodDetails : goodDetails
                })

                selectedPackages.push({ 
                    package_name: packageName,
                    services: services,
                    price : price,
                    clientDetail: clientDetail,
                    haveOptional:haveOptional,
                    haveComplementary:haveComplementary,
                    isEnglish: isEnglish,
                    pageCount: pageCount,
                    oldSession: oldSession.length>0 ? oldSession : null
                });
            }
        });

        if(breakProcess === ''){
            try {
                const submitBtn = document.getElementById("offer")
                submitBtn.innerHTML = 'Loading...'

            

                const response = await fetch(`${apiUrl}/api/v1/internalTools/commercial/generate-offer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(selectedPackages)                       
                });
                
                if(response.ok){
                    const html = await response.text();
                    const link = JSON.parse(html)
                    oldSession.push(link)
                    
                    const devis = document.getElementById("devis")
                    devis.innerHTML = `<iframe class="IframeViewer" width="100%" height="800px" src="${`${apiUrl}/api/v1/user/49/documents/${link.data.sessionId}/none/${link.data.docName}/service`}"></iframe>`
                    submitBtn.innerHTML = 'Générer l\'offre'
    
                    const download = document.getElementById("download")
                    download.innerHTML = `<button id="getDoc" data-session="${link.data.sessionId}">Télécharger</button>`
                    selectedPackages = []
                }else{
                    console.error('Error generating offer.');
                }
            }catch(error){
                console.error(error);
            }
        }
    });

  

    document.getElementById("download").addEventListener('click', async (e) => {
        e.preventDefault();
        const session = document.querySelector("#getDoc")
   
       fetch(`${apiUrl}/api/v1/internalTools/commercial/download/${session.dataset.session}/file`, { 
                method: "GET",
                headers: { "Accept": "application/octet-stream" }
            }).then(
                (res) => res.arrayBuffer()
            ).then(data => {

                var base64Str = Buffer.from(data).toString('base64');
                var binaryString = window.atob(base64Str);
                var binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                var arrBuffer = bytes;
                var newBlob = new Blob([arrBuffer], { type: "application/pdf" });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = data;
                    link.download = "Commercial.pdf";
                    link.click();
                    window.URL.revokeObjectURL(data);
                    link.remove();
            }       
        )
    })
};