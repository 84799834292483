import { orderBy } from 'lodash';
import { Field, FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Modal,
  Popup,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import Dropdowns from 'components/Dropdown';
import { Input } from 'components/Form';
import { Error } from 'components/Form';

import { updateDeclaration } from 'interfaces/formSchema/customsValidators';
import Wrapper from 'components/core/Wrapper/Wrapper';
import AddContact from 'components/AddContact/addContact';
import styled from '@emotion/styled';

const ModalEditDeclaration = ({
  userAdminOrOp,
  open,
  actualData,
  handleEditDeclaration,
  handleClose,
  partners,
  dispatch,
  intl,
  allCountries,
  allStatus,
  allCountriesLoading,
  customsOfficeLoading,
  customsOffice,
  errorHandlerData,
  companyDrivers,
  companyDriversLoading,
  statusLoading,
  categories,
  company,
  companies,
  companiesLoading,
  contacts,
  contactsError,
  contactsLoading,
  contactsTransporters,
  contactsTransportersLoading,
}: ModalEditDeclarationProps) => {
  const date = new Date(actualData.estimatedDateTimeArrival)
    .toLocaleString('en', { hour12: false })
    .split(',');

  const isSuperOperatorCompany = 49;
  let val: any = [];

  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [allStatusList, setAllStatusList]: any = useState([]);
  const [customsOfficeList, setCustomsOfficeList]: any = useState([]);
  const [transporterList, setTransporterList]: any = useState([]);
  const [driversList, setDriversList]: any = useState([]);
  const [contactsList, setcontactsList]: any = useState(false);
  const [companiesList, setCompaniesList]: any = useState(false);
  const [newCompany, setCompany]: any = useState(false);

  const [addContactModal, setaddContactModal] = useState(false);
  const [regime, setRegime]: any = useState(false)
  const [selectedList, setSelectedList]: any = useState([])
  const [defaultList, setDefaultList]: any = useState([])
  const [categoriesList, setCategoriesList]: any = useState([]);
  const [tracesList, setTraces]: any = useState([]);
  const [partnerValue, setPartnerValue]: any = useState(null);


  useEffect(() => {
    dispatch(actions.status.getAllStatus());
    dispatch(actions.data.getAllCountries());
    dispatch(actions.customsDeclaration.getAllCustomsOffice());
    dispatch(actions.customsDeclaration.getCategories({
      params: { companyId: actualData.Company_Id },
      query: {
        type: true
      }
    }));
    dispatch(actions.contact.getAllContactTransporters({
      params: {
        companyId:  actualData.Company_Id 
      }
    }));

    if(actualData.transporter){
      dispatch(
        actions.companyDriver.getAllDrivers({
          params: { companyId: actualData.transporter.id ?? actualData.Company_Id },
        })
      );
    }
    if(userAdminOrOp){
      dispatch(actions.customsDeclaration.getCompanySubscribed());
    }

    return () => {
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.status.reset(['allStatus', 'error']));
      dispatch(actions.customsDeclaration.reset(['getCategories', 'customsOffices', 'error']));
      dispatch(actions.contact.reset(['contacts', 'contactsTransporters', 'error']));
      if(userAdminOrOp){
        dispatch(actions.customsDeclaration.reset(['companySubscribed', 'error']));
      }
      if(actualData.transporter){
        return () => {
          dispatch(actions.companyDriver.reset(['companyDrivers', 'error']));
        }
      }
    };

    
  }, [dispatch, actualData, userAdminOrOp]);

  useEffect(() => {
    if (customsOffice) {
      setCustomsOfficeList(
        customsOffice.map((office, index) => {
          return {
            key: index,
            value: office.id,
            text: office.name,
          };
        })
      );
    }
  }, [customsOffice]);

  useEffect(() => {
    if (actualData.Company_Id) {
      dispatch(
        actions.company.getOne({
          params: { companyId: actualData.Company_Id },
        })
      );
      dispatch(
        actions.contact.getAllContacts({
          params: { companyId: actualData.Company_Id },
        })
      );
    }
  }, [dispatch, actualData]);

  useEffect(() => {
  if (contacts) {
      let contactList: any = []
      contacts.forEach((contact) => {
         if(contact.id !== isSuperOperatorCompany){
          contactList.push({
            id: contact.id,
            name: contact.name,
            transporter: contact.transporter,
          })
         }
      })
    setcontactsList(
      orderBy(
        contactList.map((contact, index) => {
          return {
            key: index,
            value: {
              id: contact.id, 
              name: contact.name,
              transporter: contact.transporter,
            },
            text: contact.name,
          };
        }),
        ['text']
      )
    );
  }
  }, [contacts]);

  useEffect(() => {
    if(contactsTransporters){
 
      if(company && company.transporter){
        let address = []
        address.push(company.Addresses[0])
        address.length>0 && contactsTransporters.push({
          id: company.id,
          name: company.name,
          country: company.country,
          Addresses: address
        })
      }

      setTransporterList(
        contactsTransporters.map((el, index) => {
            return {
              key: index,
              value: { id: el.id, name: el.name, address: el.Addresses[0].city},
              text: `${el.name} - (${el.country} - ${el.Addresses[0].city}) `,
            };
        })
      )
    }
  }, [contactsTransporters, company])

  useEffect(()=> {
   if(partners && contacts){
    let tab: any = []
    partners.userData.forEach((partner) => {
      contacts.forEach((contact) => {
        if(partner.id === contact.id){
          tab.push(partner.id)
        }                              
      })
    })
    setPartnerValue(tab)
   }
  }, [partners, contacts])

  useEffect(() => {
    if (allCountries) {
      setAllCountriesList(
        orderBy(
          allCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso_code,
              text: intl.formatMessage({
                id: `countries.name.${country.iso_code}`,
              }),
              flag: country.iso_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [allCountries, intl]);

  useEffect(() => {
    if(companies && userAdminOrOp){
      setCompaniesList(companies.map((c, index) => {
        return {
          key: index,
          value: c.id,
          text: c.name
        }
      }))
    }
  }, [companies, userAdminOrOp])

  useEffect(() => {
    if (allStatus) {
      setAllStatusList(
        orderBy(
          allStatus.map((s, index) => {
            return {
              key: index,
              value: s.description,
              text: s.description,
            };
          }),
          ['text']
        )
      );
    }
  }, [allStatus]);

  useEffect(() => {
    if(companyDrivers){
      setDriversList(
        orderBy(
          companyDrivers.map((d, index) => {
            return {
              key: index,
              value: parseInt(d.id),
              text: d.pseudo,
            };
          }),
          ['text']
        )
      );
    }
  }, [companyDrivers]);


  useEffect(() => {
    if(categories){
      let extraTab: any = []
      let sortedCategories = categories.sort((a, b) => {
        return a.order - b.order
      })

      sortedCategories.forEach((cat, index) => {  
        if(cat.default === false && cat.order !== null){
          extraTab.push({
            key: index,
            value: cat.description,
            text: cat.description
          })
        }    
     })
      //setCustomsTradeList(extraTab)
    }
  }, [categories])

  useEffect(() => {
    if(actualData && categories){
      let selectedItems: any = []
      categories.forEach((cat) => {
        if(actualData.types.indexOf(cat.name)>=0 && cat.type === 'customsClearance'){
          selectedItems.push(cat.name)
        }else if(actualData.types.indexOf(cat.name)>=0 && cat.type === 'mandat'){
          selectedItems.push(cat.name)
        }
      })
      selectedItems.length>0 && setSelectedList(selectedItems) 
        
      let defaultItems: any = []
      actualData.types.forEach((e) => {
        if(e !== 'accises'){
          defaultItems.push(e)
        }
      })

      defaultItems.length>0 && setDefaultList(defaultItems)

      const customs = [
        {description: 'EU Export', name: 'euExport'},
        {description: 'EU Import', name: 'euImport'}, 
        {description: 'GB export', name: 'gbExport'}, 
        {description: 'GB import', name: 'gbImport'}, 
        {description: 'GMR', name: 'gmr'}, 
        {description: 'Import from outside EU', name: 'outsideEuImport'},
        {description: 'Export to outside EU', name: 'outsideEuExport'},
        {description: 'Mandat', name: 'mandat'},    
      ]
      setCategoriesList(customs)

      setTraces([
        {description: 'Traces Export', name: 'tracesExport'},
        {description: 'Traces Import', name: 'tracesImport'}, 
      ])
    }
  }, [actualData, categories])

  useEffect(() => {
    if(actualData.fortyTwoRegime !== null){
      setRegime(actualData.fortyTwoRegime)
    }
  }, [actualData])

  useEffect(() => {
    if (contacts) {
      setcontactsList(
        orderBy(
          contacts.map((contact, index) => {
            return {
              key: index,
              value: contact.id,
              text: contact.name,
              flag: contact.country.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }

  }, [contacts]);

  const PopupTile = styled.span(() => ({
    cursor: 'pointer'
  }))
  const Instruction = styled.div(() => ({
    margin: '0.2rem 0',
    padding: '0.2rem 0'
  }))

  const getPartners = (Company_Id) => {
    if(Company_Id){
      setTransporterList([])
      setCompany(Company_Id)
      dispatch(
        actions.contact.getAllContacts({
          params: { companyId: Company_Id },
        })
      );
      dispatch(actions.contact.getAllContactTransporters({
        params: {
          companyId: Company_Id 
        }
      }));
    }
  }

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit declaration' />
      <Formik
        initialValues={{
          currentCompanyId: actualData.Company_Id || null, 
          Company_Id: (newCompany ? newCompany : actualData.Company_Id) || null,
          transporterPhoneNumber: actualData.transporterPhoneNumber || '',
          clientReference: actualData.clientReference === '' ?  '' : actualData.clientReference,
          truckLicensePlate: actualData.truckLicensePlate,
          truckOrigin: actualData.truckOrigin,
          trailerLicensePlate: actualData.trailerLicensePlate,
          trailerOrigin: actualData.trailerOrigin,
          estimatedDateArrival: actualData.estimatedDateTimeArrival.split('T')[0],
          estimatedTimeArrival: date[1].trim(),
          types: defaultList,
          customsOffice: actualData.customsOffice,
          driver: actualData.driver?.id || null,
          fortyTwoRegime: actualData.fortyTwoRegime || false,
          indRepresentation: actualData.indRepresentation || false,
          purchaseNumber: actualData.purchaseNumber || '',
          transporter: actualData.transporter || '',
          crossingStatus: actualData.crossingStatus || '',
          customsTypes: selectedList,
          permissions: partnerValue || '',
          intranetRef: actualData.intranetRef || '',
        }}
        enableReinitialize={true}
        onSubmit={handleEditDeclaration}
        validationSchema={updateDeclaration}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Grid columns='7'>
              <Grid.Column style={{maxWidth: '130px'}}>
                <Field
                  label='Regime 42'
                  checked={regime}
                  name='fortyTwoRegime'
                  onClick={() => {
                    setRegime(!regime)
                    setFieldValue('fortyTwoRegime', !values.fortyTwoRegime);
                  }}
                  component={Checkbox}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
                 <Grid.Column>
                <Field
                  label='IR ?'
                  checked={values.indRepresentation}
                  name='indRepresentation'
                  onClick={() => {
                    setFieldValue('indRepresentation', !values.indRepresentation);
                  }}
                  component={Checkbox}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>
                </Grid.Column>
                {(typeof defaultList === 'object' || defaultList.find((e) => e !== 'mandat' && e !== 'accises')) && (
                <>
                <Grid.Column style={{maxWidth: '71px'}}>
                  <p><b>Types :</b></p>
                </Grid.Column>
                <Grid.Column style={{minWidth: '168px'}}>                   
                  {categoriesList.length>0 && (      
                  <Popup trigger={<PopupTile><Icon name='dropdown'/> Customs clearance</PopupTile>} flowing hoverable>
                        <Instruction>Select multiple</Instruction>
                  <FieldArray
                    name='types'
                    render={(arrayHelper) => (
                      <>
                        {categoriesList.length>0 && categoriesList.map((el: any, i) => {                         
                            return (
                              <Wrapper key={i}>
                                <Wrapper>
                                  <Wrapper>
                                    <Field
                                      key={i}
                                      name={`types[${i}]`}
                                      component={Checkbox}
                                      label={
                                        el.description
                                      }
                                      onChange={() => {
                                        if (
                                          values.types.includes(
                                            el.name
                                          )
                                        ) {
                                          arrayHelper.remove(
                                            values.types.findIndex(
                                              (e) => e === el.name
                                            )
                                          );
                                        } else {
                                          arrayHelper.push(el.name);
                                        }
                                      }}
                                      checked={                             
                                        values.types.includes(
                                          el.name
                                        )
                                      }
                                      meta={{
                                        errors,
                                        touched,
                                        setFieldValue,
                                        setFieldTouched,
                                      }}
                                    />
                                  </Wrapper>
                                </Wrapper>
                              </Wrapper>
                            );
                        
                        })}
                      </>
                  )}             
                  /></Popup>
                  )}   
                </Grid.Column>
                </>
              )}
              {!defaultList.find((e) => e === 'accises') && (
                <>
                <Grid.Column style={{minWidth: '168px'}}>
                {tracesList.length>0 && (      
                  <Popup trigger={<PopupTile><Icon name='dropdown'/> Traces</PopupTile>} flowing hoverable>
                  <Instruction>Select multiple</Instruction>
                  <FieldArray
                    name='types'
                    render={(arrayHelper) => (
                      <>
                        {tracesList.length>0 && tracesList.map((el: any, i) => {                         
                            return (
                              <Wrapper key={i}>
                                <Wrapper>
                                  <Wrapper>
                                    <Field
                                      key={i}
                                      name={`types[${i}]`}
                                      component={Checkbox}
                                      label={
                                        el.description
                                      }
                                      onChange={() => {
                                        if (
                                          values.types.includes(
                                            el.name
                                          )
                                        ) {
                                          arrayHelper.remove(
                                            values.types.findIndex(
                                              (e) => e === el.name
                                            )
                                          );
                                        } else {
                                          arrayHelper.push(el.name);
                                        }
                                      }}
                                      checked={                             
                                        values.types.includes(
                                          el.name
                                        )
                                      }
                                      meta={{
                                        errors,
                                        touched,
                                        setFieldValue,
                                        setFieldTouched,
                                      }}
                                    />
                                  </Wrapper>
                                </Wrapper>
                              </Wrapper>
                            );
                        
                        })}
                      </>
                  )}             
                  /></Popup>
                  )}   
                </Grid.Column>
                <Grid.Column style={{minWidth: '143px'}}>          
                  <Field
                    label='Customs clerk'
                    checked={values.types.includes('customsClerk')}
                    name='customsClerk'
                    onClick={() => {
                      setFieldValue(
                        'types',
                        values.types.includes('customsClerk')
                          ? values.types.filter((e) => e !== 'customsClerk')
                          : [...values.types, 'customsClerk']
                      );
                    }}
                    component={Checkbox}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
             
                </Grid.Column>
                </>
              )}
              </Grid>
              {errors.types && <Error msg={errors.types} />}
              <Divider hidden />
              {addContactModal && (
                <AddContact
                  open={addContactModal}
                  handleClose={() => setaddContactModal(false)}
                  companyId={actualData.Company_Id}
                />
              )}       
              <Grid columns='3'>
                <Grid.Column>
                  {userAdminOrOp && (
                    <Field
                      name='Company_Id'
                      placeholder='Select company'
                      options={companiesList}
                      component={Dropdowns}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      loading={companiesLoading}
                      uniqueError={
                        errorHandlerData.includes('allCountries') ? true : false
                      }
                      onChange={(value) => {
                        getPartners(value) 
                      }}
                    />
                  )} 
                </Grid.Column>
                <Grid.Column>          
                <Button
                  icon='plus'
                  type='button'
                  content='Add contact'
                  onClick={() => {
                    setaddContactModal(true);
                  }}
                /> 
                </Grid.Column>
              </Grid>        
              <Grid columns='2'>
                <Grid.Column>
                  <Field
                    label="Truck's origin"
                    name='truckOrigin'
                    placeholder='Choose the country of origin of the truck'
                    options={allCountriesList}
                    component={Dropdowns}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    loading={allCountriesLoading}
                    uniqueError={
                      errorHandlerData.includes('allCountries') ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Truck license plate'
                    name='truckLicensePlate'
                    placeholder='AA123AA'
                    component={Input}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
              </Grid>
              <Divider hidden />
              <Grid columns='2'>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Trailer origin'
                    name='trailerOrigin'
                    placeholder='Choose the country of origin of the trailer'
                    options={allCountriesList}
                    component={Dropdowns}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    loading={allCountriesLoading}
                    uniqueError={
                      errorHandlerData.includes('allCountries') ? true : false
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Trailer license plate'
                    name='trailerLicensePlate'
                    placeholder='AA123AA'
                    component={Input}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
              </Grid> 
              <Grid>          
                <Grid.Column>
                <Field
                   label="Add partners"
                   name='permissions'
                   placeholder='Choose partners'
                   options={contactsList}
                   fluid 
                   multiple                   
                   clearable
                   selection
                   component={Dropdowns}
                   meta={{
                     errors,
                     touched,
                     setFieldValue,
                     setFieldTouched,
                   }}
                   loading={contactsLoading}
                   uniqueError={
                     contactsError.includes('contactsList')
                       ? true
                       : false
                   }
                   onChange={(value) => {                                                      
                     if(value){
                       val.push(value)
                       contactsList.forEach((contact, index) => {
                         if(contact.value.id === val[0].id) {
                           val.push(index, {
                             name: contact.value.name,
                             user_Id: contact.value.id,
                             transporter: contact.value.transporter,
                           })
                         }
                       })
                       val = []
                     }                         
                   }}
                 />
                </Grid.Column>                        
              </Grid>                     
              <Grid>          
                <Grid.Column>
                  <Field
                    label="Transporter"
                    name='transporter'
                    placeholder='Choose transporter'
                    options={transporterList}
                    component={Dropdowns}
                    loading={contactsTransportersLoading}
                    disabled={transporterList.length===0}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    uniqueError={
                      errorHandlerData.includes('contactsTransporters')
                        ? true
                        : false
                    }
                    onChange={(value) => {
                      if(value){
                        setDriversList(false)
                        dispatch(
                          actions.companyDriver.getAllDrivers({
                            params: { companyId: value.id },
                          })
                        );                                                  
                      }
                      else{
                        dispatch(
                          actions.companyDriver.getAllDrivers({
                            params: { companyId: actualData.Company_Id  },
                          })
                        );        
                      }                                                    
                    }}
                  />
                </Grid.Column>                        
              </Grid>           
              <Grid>
                <Grid.Row columns='3'>
                  <Grid.Column>
                    {(driversList.length && (
                      <Field
                        label='Drivers'
                        name='driver'
                        placeholder={`${actualData.driver ? actualData.driver.pseudo : 'Choose driver'}`}
                        loading={companyDriversLoading}
                        options={driversList}
                        component={Dropdowns}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        uniqueError={
                          errorHandlerData.includes('driver')
                            ? true
                            : false
                        }
                        onChange={(value) => {                          
                          setFieldValue('driver', value.id);                                              
                        }}
                      />)) || (
                      <Field
                        label="Driver's phone number"
                        name='transporterPhoneNumber'
                        placeholder='+33123456789'
                        component={Input}
                        type='tel'
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                      />
                    )}                    
                </Grid.Column>
                <Grid.Column>              
                  <Field
                    mandatory
                    label='Estimated arrival date'
                    name='estimatedDateArrival'
                    component={Input}
                    type='date'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    mandatory
                    label='Estimated arrival time'
                    name='estimatedTimeArrival'
                    component={Input}
                    type='time'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                  </Grid.Column>
                </Grid.Row> 
              </Grid>        
              <Divider hidden />
              <Field
                options={customsOfficeList}
                loading={customsOfficeLoading}
                mandatory
                label='Customs office of arrival'
                name='customsOffice'
                placeholder='Customs office'
                component={Dropdowns}
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
                uniqueError={
                  errorHandlerData.includes('customsOffice') ? true : false
                }
              />
              <Divider hidden />
              <Grid columns='2'>
                <Grid.Column>
                  <Field
                    label='Client reference'
                    name='clientReference'
                    placeholder='123456789'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    label='PO'
                    name='purchaseNumber'
                    placeholder='po...'
                    component={Input}
                    type='text'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Column>
              </Grid>
          
                <Grid columns={2}>
                  {actualData.Company_Id  === 560 && (
                    <Grid.Column>
                    <Field
                      label='ASD Intranet ref.'
                      name='intranetRef'
                      placeholder='intranet ref...'
                      component={Input}
                      type='text'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                    </Grid.Column>
                  )}
                   
                <Grid.Column>
                 <Field
                    label="Set transport status"
                    name='crossingStatus'
                    placeholder='Choose ...'
                    options={allStatusList}
                    component={Dropdowns}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    loading={statusLoading}
                    uniqueError={
                      errorHandlerData.includes('allStatus') ? true : false
                    }
                  />
              </Grid.Column>

           
             
           
              </Grid>          
              <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.customsDeclaration.companySubscribedSelector(state),
    companiesLoading: selectors.customsDeclaration.companySubscribedLoadingSelector(state),
    categoriesLoading: selectors.customsDeclaration.getCategoriesLoadingSelector(state),
    categories: selectors.customsDeclaration.getCategoriesSelector(state),
    companyDriversLoading: selectors.companyDriver.getAllDriversLoadingSelector(state),
    companyDrivers: selectors.companyDriver.getAllDriversSelector(state),
    allStatus: selectors.status.allStatusSelector(state),
    statusLoading: selectors.status.loadingSelector(state),
    allCountries: selectors.data.allCountriesSelector(state),
    allCountriesLoading: selectors.data.allCountryLoadingSelector(state),
    customsOfficeLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    customsOffice: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    company: selectors.company.companySelector(state),
    contacts: selectors.contact.contactsSelector(state),
    contactsError: selectors.contact.errorSelector(state),
    contactsLoading: selectors.contact.getContactsLoadingSelector(state),
    contactsSuccess: selectors.contact.getContactsSuccessSelector(state),
    contactsTransporters: selectors.contact.contactsTransportersSelector(state),
    contactsTransportersLoading: selectors.contact.getAllContactTransportersLoadingSelector(
      state
    ),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  currentCompanyId: number;
  Company_Id: number;
  types: Array<string>;
  transporterPhoneNumber: string;
  clientReference: string;
  truckLicensePlate: string;
  truckOrigin: string;
  trailerLicensePlate: string;
  trailerOrigin: string;
  driver: string;
  transporter: any;
  customsOffice: number;
  fortyTwoRegime: boolean;
  indRepresentation: boolean;
  purchaseNumber: string;
  crossingStatus: string;
  estimatedDateArrival: string;
  estimatedTimeArrival: string;
  customsTypes: string[];
  permissions: [];
  intranetRef: string;
};

interface ModalEditDeclarationProps {
  open: boolean;
  actualData: any;
  setCompany;
  companySelected;
  handleEditDeclaration: (values: declarationData) => void;
  handleClose: () => void;
  partners,
  userAdminOrOp,
  match,
  dispatch,
  intl;
  allCountries;
  allStatus;
  statusLoading;
  allCountriesLoading;
  customsOfficeLoading;
  customsOffice;
  customsTrade;
  fortyTwoRegime;
  driver;
  purchaseNumber;
  errorHandlerData;
  contactError;
  company;
  companies;
  companiesLoading;
  contacts;
  contactsError
  contactsLoading;
  contactsTransporters;
  contactsTransportersLoading;
  companyDrivers;
  companyDriversLoading;
  categories;
  categoriesLoading;
}

export default enhance(ModalEditDeclaration);
